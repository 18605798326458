import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'wow.js/css/libs/animate.css'
import wow from 'wow.js'// 动态效果样式文件
// import 'lib-flexible/flexible.js'
// import 'amfe-flexible';

Vue.config.productionTip = false
Vue.prototype.$wow = wow
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="header">
    <!-- 顶部 -->
    <!-- 电脑端顶部 -->
    <div class="web">
      <div class="WebMain">
        <div class="left">
          <img
            v-show="$store.state.style === 'black'"
            src="../../assets/img/header/logo.png"
            alt=""
          />
          <img
            v-show="$store.state.style === 'white'"
            src="../../assets/img/header/logo2.png"
            alt=""
          />
        </div>
        <div class="right">
          <ul>
            <li
              @click="checkPath(item.path)"
              @mouseleave="defaultActive"
              @mouseover="activeHover(item.path)"
              :class="activeName === item.title ? 'active' : ''"
              v-for="item in menulist"
              :key="item.id"
            >
              <span class="p18">{{ item.title }}</span>
            </li>
          </ul>
          <div class="language" v-if="$store.state.style === 'black'">
            <img
              @click="changeStyle"
              src="../../assets/img/header/黑夜@2x.png"
              alt=""
            />
            <img
              class="language1"
              src="../../assets/img/header/英国@2x.png"
              
              alt=""
            />
            <el-dropdown @command="changeLanguage">
              <span class="el-dropdown-link">
                EN<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/chinese">CH</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="language" v-else>
            <img
              @click="changeStyle"
              src="../../assets/img/header/白天-晴@2x.png"
              alt=""
            />
            <img
              class="language1"
              src="../../assets/img/header/五星红旗@2x.png"
           
              alt=""
            />
            <el-dropdown @command="changeLanguage">
              <span class="el-dropdown-link">
                EN<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/chinese">CH</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <span class="activeBottom" :style="{ left: leftWidth }"></span>
        </div>
      </div>
    </div>

    <!-- 手机顶部 -->
    <div class="phone">
      <div class="left">
        <img
          v-show="$store.state.style === 'black'"
          src="../../assets/img/header/logo.png"
          alt=""
        />
        <img
          v-show="$store.state.style === 'white'"
          src="../../assets/img/header/logo2.png"
          alt=""
        />
        <!-- <img src="../../assets/img/header/logo.png" alt="" /> -->
      </div>
      <!-- <div class="center">
        <span>{{ activeName }}</span>
      </div> -->
      <div class="right">
        <div class="language" v-if="$store.state.style === 'black'">
          <img
            @click="changeStyle"
            src="../../assets/img/header/黑夜@2x.png"
            alt=""
          />
          <img
            src="../../assets/img/header/English.png"
            @click="changeLanguage"
            alt=""
          />
        </div>
        <div class="language" v-else>
          <!-- <span>EN</span> -->
          <img
            @click="changeStyle"
            src="../../assets/img/header/白天-晴@2x.png"
            alt=""
          />
          <img
            class="language1"
            src="../../assets/img/header/语言-英@2x.png"
            @click="changeLanguage"
            alt=""
          />
        </div>
        <i class="el-icon-s-fold" @click="openDrawer"></i>
      </div>
    </div>
    <el-drawer
      title="0"
      :visible.sync="drawer"
      :with-header="false"
      :append-to-body="true"
      size="55%"
      :modal-append-to-body="false"
    >
      <ul class="drawerUl">
        <li
          :class="$route.path.indexOf(item.path) !== -1 ? 'active' : ''"
          v-for="item in menulist"
          :key="item.id"
          @click="checkPath(item.path)"
        >
          <span>{{ item.title }}</span>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
import { attachmentListAPI } from "@/api/attachment/attachment";
export default {
  data() {
    return {
      leftWidth: "9%", // 默认导航下面滑块的位置
      menulist: [
        {
          id: 1,
          title: "About",
          path: "/home",
          text: {
            title: "TradeWise Management system",
            summary: "Focus on foreign exchange member management",
          },
        },
        {
          id: 2,
          title: "Product",
          path: "/product",
          text: {
            title:
              "Efficient Way to Improve Your Management and Boost Your Productivity",
            summary: "An Intelligent solutions for Forex Brokers",
          },
        },
        // { id: 3, title: 'News', path: '/trends', text: { title: 'Products and services', summary: 'First-class products, first-class service' } },
        // { id: 4, title: 'About us', path: '/contact', text: {  title: 'A Tool All Forex Brokers will Need ', summary: 'to stay ahead of the competition' } },
      ], // 菜单
      drawer: false, // 侧边栏模态框
      bannerList: [
        { id: 1, title: "/product", path: "Products and services.png" },
        { id: 2, title: "/contact", path: "About us.png" },
        // {id: 1, path: '/home', url: '', title: '', summary: ''},
      ],
      imgPath: "",
    };
  },
  methods: {
    // 打开侧边栏
    openDrawer() {
      // console.log(1111)
      this.drawer = true;
    },
    // 切换颜色样式
    changeStyle() {
      if (this.$store.state.style === "white") {
        this.$store.state.style = "black";
      } else {
        this.$store.state.style = "white";
      }
    },
    // 切换路由
    checkPath(val) {
      if (val === "/home") {
        this.leftWidth = "0%";
      } else if (val === "/product") {
        this.leftWidth = "34%";
      } else if (val === "/contact") {
        this.leftWidth = "0%";
      }
      this.$router.push(val);
      // setTimeout(() => {

      // }, 1000);
      this.getList();
      this.drawer = false;
    },
    // 获取附件列表
    async getList() {
      // console.log(this.activeName)
      this.bannerList.forEach((item) => {
        // this.$route.path
        // console.log(item)
        if (item.title === this.$route.path) {
          console.log(item.title);
          this.imgPath = item.path;
          this.$store.commit("changeBannerInfo", this.imgPath);
          console.log(this.imgPath);
        }
        //
      });
    },
    // 鼠标移入时
    activeHover(val) {
      if (val === "/home") {
        this.leftWidth = "0%";
      } else if (val === "/product") {
        this.leftWidth = "34%";
      } else if (val === "/contact") {
        this.leftWidth = "0%";
      }
    },
    // 鼠标离开时，回到原来的位置
    defaultActive() {
      if (this.$route.path === "/home") {
        this.leftWidth = "0%";
      } else if (this.$route.path === "/product") {
        this.leftWidth = "34%";
      } else if (this.$route.path === "/contact") {
        this.leftWidth = "0%";
      }
    },
    changeLanguage(val) {
      // console.log(this.$route.path)
      if (this.$route.path === "/product") {
        this.$router.push("/chinese/product");
      } else {
        this.$router.push("/chinese/home");
      }
    },
  },

  computed: {
    activeName() {
      let name = "";
      let path = this.$route.path;
      // if (path.indexOf('detail') !== -1) {
      //   path = '/trends'
      // }
      // console.log(path);
      this.menulist.forEach((item) => {
        if (item.path === path) {
          name = item.title;
          this.$store.commit("changeBannerText", item.text);
        }
      });
      // console.log(path)
      // console.log(name)
      return name;
    },
  },
  mounted() {
    // this.getList();
    if (this.$route.path === "/home") {
      this.leftWidth = "0%";
    } else if (this.$route.path === "/product") {
      this.leftWidth = "34%";
    } else if (this.$route.path === "/contact") {
      this.leftWidth = "0%";
    }

    //监听并处理函数
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.drawerUl {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
    line-height: 55px;
    padding-left: 15px;
    border-bottom: 5px solid white;

    span {
      font-size: 16px;
    }
  }

  .active {
    border-bottom: 5px solid var(--btn-color);

    span {
      color: var(--btn-color);
    }
  }
}

.header {
  .web {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    // background: #FFFFFF;
    // box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);

    .WebMain {
      // margin: 0 auto;
      height: 100%;
      top: 0;
      z-index: 2;
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        img {
          height: 26px;
          width: 260px;
        }
      }

      .right {
        position: relative;
        display: flex;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          // padding-right: 105px;

          li {
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            // padding: 11px 0;
            // min-width: 64px;
            margin-right: 76px;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
            span {
              // font-weight: 600;
              // color: var(--custom-color);
            }

            &:hover {
              transition: all 0.3s;
              // color: var(--custom-color);
            }

            // .bottomBorder {
            //   position: absolute;
            //   width: 28px;
            //   height: 6px;
            //   background: #3AC2E8;

            // }

            &:last-child {
              margin-right: 0px;
            }
          }

          .active {
            font-weight: 600;
            // color: white;
          }
        }

        .activeBottom {
          display: block;
          width: 28px;
          height: 6px;
          background: var(--custom-color);
          border-radius: 2px;
          position: absolute;
          bottom: -8px;
          transition: all 0.3s;
        }
      }

      .language {
        // position: absolute;
        // right: 0;
        // height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 105px;

        img {
          // height: 30px;
          width: 30px;
        }
        .language1 {
          margin-left: 30px;
          margin-right: 12px;
        }
      }
    }
  }

  .phone {
    z-index: 2;

    padding: 0 10px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;

    .left,
    // .center,
    .right {
      width: 120px;
      // width: 33%;
    }

    .left {
      img {
        width: 150px;
      }
    }

    .center {
      text-align: center;

      span {
        font-size: 16px;
        font-weight: 600;
        color: #222;
        // color: var(--custom-color);
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .language {
        padding-top: 3px;
        margin-right: 15px;
        display: block;

        img {
          height: 22px;
        }
      }

      i {
        font-size: 28px;
        // color: var(--btn-color);
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1180px) {
  .header .web .WebMain .right ul li {
    margin-right: 15px;
  }

  .header .web .WebMain .left img {
    width: 200px;
    height: auto;
  }
}

@media screen and (max-width: 850px) {
  .header {
    .web {
      display: none;
    }

    .phone {
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
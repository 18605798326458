<template>
    <div class="header">
        <!-- 顶部 -->
        <div class="left">
            <i v-if="$store.state.isCollapse" class="el-icon-s-unfold" @click="openMenu"></i>
            <i v-else class="el-icon-s-fold" @click="openMenu"></i>
        </div>
        <div class="right">
                <!-- <img class="avatar" src="../assets/logo.png" alt=""> -->
           
            
            
                <el-dropdown @command="handleCommand">
                    
                    <span class="el-dropdown-link">
                        <span class="name">{{ userInfo.username }}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="/admin/updatePassword">修改信息</el-dropdown-item>
                        <el-dropdown-item command="/admin/login">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: {}
            // isCollapse:true
        }
    },
    methods: {
        // 打开侧边栏
        openMenu() {
            this.$store.commit('changeCollapse')
        },
        handleCommand(command) {
        console.log(command);
        if(command === '/admin/login') {
            sessionStorage.clear();
        }
        this.$router.push(command);
        
        // if(this)
      }
    },
    created(){
        this.userInfo = JSON.parse(sessionStorage.getItem('user'))
    }
}
</script>

<style lang="scss" scoped>
.header{
    display: flex;
    justify-content: space-between;
    color: var(--white-color);
    // z-index: 5;
    .left{
       
    }
    .right{
        display: flex;
        align-items: center;
        // line-height: 60px;
        .avatar{
                width: 30px;
                border-radius: 50%;
            
        }
        .name{
            display: inline-block;
            margin-left:  10px;
        }
        .el-dropdown-link{
            color: var(--white-color);
        }
    }
}
</style>
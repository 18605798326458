<template>
  <div :class="$store.state.style + ' index'">
    <div class="indexHead">
      <Header></Header>
    </div>
    <div class="indexMain">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/ChineseWeb/Header.vue";
import Footer from "@/components/ChineseWeb/Footer.vue";
// import Banner from '@/components/web/Banner.vue';
export default {
  data() {
    return {
      isAb: true,
    };
  },
  components: { Header, Footer },
  created() {
    let userAgentInfo = navigator.userAgent;
    let mobileAgents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];

    let mobile_flag = false;

    //根据userAgent判断是否是手机
    for (let v = 0; v < mobileAgents.length; v++) {
      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        mobile_flag = true;
        break;
      }
    }
    this.$store.commit("changeIsPhone", mobile_flag);
    // console.log(mobile_flag);
  },
  methods: {
    handleScroll() {
      // console.log(document.documentElement.scrollTop)
      if (Number(document.documentElement.scrollTop) > 100) {
        this.isAb = false;
      } else {
        this.isAb = true;
      }
    },
  },
  computed: {
    activeName() {
      let name = "";
      let path = this.$route.path;
      // if (path.indexOf('detail') !== -1) {
      //   path = '/trends'
      // }
      console.log(path);
      this.menulist.forEach((item) => {
        if (item.path === path) {
          name = item.title;
          this.$store.commit("changeBannerText", item.text);
        }
      });
      // console.log(path)
      // console.log(name)
      return name;
    },
  },
  mounted() {
    //监听并处理函数
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.black {
  color: white;
  background: #2b2d33;
  .header {
    background: rgba(62, 68, 84, 0.5);
    backdrop-filter: blur(3px);

    color: #dbdbdb;
    .web .WebMain .right ul .active {
      color: #fff;
    }
  }
  .home {
    .bottom {
      background: #565d66;
      // background: url("../../assets/img/index/编组 8-min.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .product {
    .characteristic {
      // background: url("../../assets/img/product/矩形 2-min.png");

      background: #565d66;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .explain {
      // background: url("../../assets/img/product/矩形 2-min.png");

      background: #565d66;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .WebMain .item .num {
        border-left: 2px solid white;
      }
    }
    .centerContent{
      
      
background: #2B2D33;
          }
  }
  .footer {
    input {
      // border-bottom: 1px solid #414141 !important;
      color: white;
      &::placeholder {
        color: white;
      }
    }
  }
}
.white {
  color: #000000;

  background: #ebedf0;

  .header {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(3px);

    color: #4d4d4d;
  }
  .home {
    .bottom {
      background: #fafafa;
      // background: url("../../assets/img/index/背景-2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .product {
    .characteristic {
      // background: url("../../assets/img/product/背景-2.png");

      background: #ffffff;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .explain {
      // background: url("../../assets/img/product/背景-1.png");

      background: #ffffff;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .WebMain .item .num {
        border-left: 2px solid black;
      }
    }
    .centerContent{
      
      background: #2B2D33;
          }
  }
  .footer {
    input {
      // border-bottom: 1px solid #414141 !important;
      color: #414141;
      &::placeholder {
        color: #414141;
      }
    }
  }
}
.index {
  position: relative;
  a {
    text-decoration: none;
    color: white;
  }
  .indexHead {
    width: 100%;
    right: 0px;
    // position: absolute;
    position: sticky;
    top: 0px;
    z-index: 1;
    // background: #fff;
  }
  .noAb {
    position: sticky;
  }

  .WebMain {
    p {
      margin: 0;
      padding: 0;
    }

    .DivSolid {
      width: 100px;
      height: 6px;
      background: var(--second-color);
    }
    .DivTitle {
      font-size: 32px;
      // font-weight: 600;
      color: #000000;
      line-height: 45px;
      // padding-top: 60px;
      padding-bottom: 15px;

      // text-align: center;
      // border-bottom: 1px solid #777777;
      // text-shadow: 0px 1px 2px rgba(0,0,0,0.35);
    }
  }
}
</style>
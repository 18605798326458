<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import WOW from 'wow.js'
export default {
  name: "app",
  components: {},
  mounted() {
    this.$nextTick(() => {
      new WOW().init();
    });
  }
};
</script>


<style lang="scss">
@import "./assets/css/color.css";

#app {
  font-family: ArialHebrew-Bold, ArialHebrew;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0px;
  padding: 0px;
}

.WebMain {
  width: 1200px;
  margin: 0 auto;
}
.p30 {
  font-size: 30px;
}
.p28 {
  font-size: 28px;
}
.p26 {
  font-size: 26px;
}
.p24 {
  font-size: 24px;
}
.p22 {
  font-size: 22px;
}
.p20 {
  font-size: 20px;
}
.p18 {
  font-size: 18px;
}
.p14 {
  font-size: 14px;
}

p {
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 1179px) {
  .WebMain {
    max-width: 850px;
  }
  .newsContent {
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .WebMain {
    width: 90%;
  }
  .p30 {
  font-size: 26px;
}
  .p28 {
    font-size: 22px;
  }
  .p22 {
    font-size: 18px;
  }
  .p20 {
    font-size: 16px;
  }
  .p18 {
    font-size: 16px;
  }
}

.el-table,
.tableContent {
  min-height: 500px;
}

.el-table th.gutter {
  display: table-cell !important;
}

.el-table .el-table__body .el-table__cell {
  height: 32px !important;
  padding: 0px !important;
  line-height: 32px !important;
  font-size: 14px;
}

.el-table th.el-table__cell {
  height: 35px !important;
  padding: 0px !important;
  line-height: 35px !important;
  font-size: 14px;
}

// // 取消数字输入框后的上下减
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

// 设置按钮区域的样式
.searchBtn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  // align-items: center;
  .el-button {
    margin-left: 20px !important;
    // margin-top: 20px;
    // padding-top: 20px;
  }

  .el-date-editor {
    margin-right: 20px;
  }

  .el-input,
  .el-select,
  .el-cascader,
  .el-date-picker {
    width: 150px !important;
    margin-right: 20px;
    // margin-bottom: 20px;
  }

  span {
    display: inline-block;
    margin-right: 10px;
    // line-height: 32px;
  }
}

.el-dialog__header {
  padding: 0px !important;
}

.block {
  text-align: center;
  // margin:0 auto;
  margin-top: 10px;
}
</style>
